<template>
	<div class="home">
		<table-module />
	</div>
</template>

<script>
import tableModule from '@/components/pages/maint/module/tableModule.vue';

export default {
	components: {
		'table-module': tableModule,
	}
};
</script>

<style>

</style>
