<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="modules"
      class="elevation-1"
			dense
			:search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Module Maintenance</v-toolbar-title>
          <v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="search"
				label="Search"
				single-line
				hide-details
			></v-text-field> 
			<vue-excel-xlsx
				:data="modules"
				:columns="columnHeaders"
				:file-name="module_name"
				>
				<v-btn icon alt class="mt-2" :color="modules.length=== 0 ? 'gray' : 'primary'" :disabled="modules.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
			</vue-excel-xlsx>
        </v-toolbar>
      </template>
        <template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
    </v-data-table>
    <create-module v-if="role_access > 1" />
  </div>
</template>

<script>
import createModule from './createModule.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
			module_name: 'Modules',
			role_access: 1,
			nextPageRegularTrx: '',
			search: ''
		};
	},
	components: {
		'create-module': createModule
	},
	computed: {
		...mapGetters( {
			modules: 'module/module',
			headers: 'module/headers',
			columnHeaders: 'module/columnHeaders',
			editedItem: 'module/editedItem',
			defaultItem: 'module/defaultItem',
			dialog: 'module/dialog',
			editedIndex: 'module/editedIndex',
			isLoading: 'module/isLoading',
			valid: 'module/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('module/getModule');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('module/setEditedIndex', this.modules.indexOf(item));
			this.$store.dispatch('module/setEditedItems', Object.assign({}, item));
			this.$store.dispatch('module/setDialog', true);
			this.$store.dispatch('module/setValid', true);
			this.$store.dispatch('module/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.module_name} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('module/setEditedIndex', this.modules.indexOf(item));
					this.$store.dispatch('module/deleteModule', item);
				}
			});
		}
	}
};
</script>